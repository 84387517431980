<template>
  <div class="case-view" :key="caseViewKey">
    <v-row mb-10>
      <v-col sm="12">
        <span class="headline">{{ caseObject.UniqueIdentifier }} - {{ caseObject.patientName }}</span>
      </v-col>
    </v-row>
    <v-form>
      <v-row dense>
        <v-col sm="2">
          <v-text-field v-model="caseObject.caseType" label="Case Type" readonly></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-text-field v-model="caseObject.UniqueIdentifier" label="Unique Identifier" readonly></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field v-model="caseObject.caseStatus" label="Case Status" readonly></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field
            v-model="caseObject.currentlyAssignedRep"
            label="Currently Assigned Rep"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col sm="4">
          <v-text-field v-model="caseObject.coveringRepEmail" label="MPS E-mail" readonly></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-text-field
            v-model="shareDateFormated"
            readonly
            label="Share Date"
            append-icon="mdi-calendar"
          ></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-text-field
            v-model="needDateFormated"
            readonly
            label="Need by Date"
            append-icon="mdi-calendar"
          ></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-switch v-model="caseObject.isUrgent" readonly label="Urgent" class="pl-10"></v-switch>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col sm="4">
          <v-text-field v-model="caseObject.patientName" readonly label="Patient Name"></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-text-field v-model="caseObject.primaryExamId" readonly label="Primary Exam Id"></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-text-field v-model="caseObject.secondaryExamId" readonly label="Secondary Exam Id"></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-switch v-model="caseObject.isTDA" label="TDA" readonly class="pl-10"></v-switch>
        </v-col>
        <v-col sm="2">
          <v-text-field v-model="caseObject.segRegion" readonly label="Seg Region"></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-form>
      <v-row dense>
        <v-col sm="8">
          <v-text-field v-model="caseObject.MRN" readonly label="MRN"></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-text-field v-model="caseObject.segWorkflow" readonly label="Seg Workflow"></v-text-field>
        </v-col>
        <v-col sm="2">
          <v-text-field
            v-model="caseObject.thaVersion"
            readonly
            label="THA Version"
            v-if="caseObject.caseType == 'THA' || caseObject.caseType == 'Cadaver'"
          ></v-text-field>
          <v-text-field
            v-model="caseObject.tkaVersion"
            readonly
            label="TKA Version"
            v-if="caseObject.caseType == 'TKA'"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col sm="4">
          <v-text-field v-model="caseObject.operativeSide" readonly label="Operative side"></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field v-model="caseObject.gender" readonly label="Gender"></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field
            v-if="caseObject.surgeon"
            v-model="caseObject.surgeon.displayName"
            readonly
            label="Surgeon"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense v-if="this.caseObject.caseType === 'TKA'">
        <v-col sm="4">
          <v-text-field v-model="caseObject.insertType" readonly label="Insert Type"></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field v-model="caseObject.basePlate" readonly label="Base Plate Type"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense v-if="this.caseObject.caseType !== 'TKA'">
        <v-col sm="4">
          <v-text-field v-model="caseObject.approach" readonly label="Approach"></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field
            v-model="caseObject.acetabularComponentType"
            readonly
            label="Acetabular Component"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field v-model="caseObject.femoralComponentType" readonly label="Femoral Component"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col sm="12">
          <v-textarea
            label="Internal Case Notes"
            v-model="caseObject.internalCaseNotes"
            readonly
            rows="2"
            dense
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  data: () => ({
    pageName: 'Case',
    caseViewKey: 0,
  }),
  computed: {
    ...mapGetters('cases', { caseObject: 'getCase', loading: 'getLoading' }),
    currentlyAssignedRep() {
      return this.caseObject.currentlyAssignedRep !== '';
    },
    shareDateFormated: {
      get() {
        return this.caseObject.shareDate ? this.$moment(this.caseObject.shareDate).format('L') : null;
      },
      set() {},
    },
    needDateFormated: {
      get() {
        return this.caseObject.needDate ? this.$moment(this.caseObject.needDate).format('L') : '';
      },
      set() {},
    },
  },
  methods: {},
  watch: {
    caseObject: {
      deep: true,
      handler() {
        console.log('case Object: ', this.caseObject);
      },
    },
    '$route.params.id': {
      deep: true,
      handler() {
        this.caseViewKey += 1;
        this.$store.dispatch('cases/getCase', this.$route.params.id);
      },
    },
  },
  async created() {
    this.$store.dispatch('cases/getCase', this.$route.params.id);
  },
};
</script>

<style></style>
