var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"on-hold"},[_c('v-dialog',{attrs:{"persistent":"","width":"720px"},model:{value:(_vm.isOnHoldDialogVisible),callback:function ($$v) {_vm.isOnHoldDialogVisible=$$v},expression:"isOnHoldDialogVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"amber"},[_c('span',{staticClass:"headline"},[_vm._v("On-Hold - "+_vm._s(_vm.caseObject.UniqueIdentifier))])]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('v-select',{attrs:{"items":_vm.onHoldReasons,"rules":_vm.onHoldReasonRules,"required":"","label":"Select On-Hold reason(s)","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"x-small":""}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.onHoldReason.length - 1)+" more) ")]):_vm._e()]}}]),model:{value:(_vm.onHoldReason),callback:function ($$v) {_vm.onHoldReason=$$v},expression:"onHoldReason"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-textarea',{attrs:{"label":"On-Hold Case Notes","rows":"3"},model:{value:(_vm.onHoldCaseNotes),callback:function ($$v) {_vm.onHoldCaseNotes=$$v},expression:"onHoldCaseNotes"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('v-select',{attrs:{"items":_vm.poorScanReasons,"label":"Select poor scan reason(s)","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"x-small":""}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.poorScanReason.length - 1)+" more) ")]):_vm._e()]}}]),model:{value:(_vm.poorScanReason),callback:function ($$v) {_vm.poorScanReason=$$v},expression:"poorScanReason"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('v-select',{attrs:{"items":_vm.rightFirstTimeCodes,"label":"Select right first time code(s)","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"x-small":""}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption",attrs:{"s":""}},[_vm._v(" (+"+_vm._s(_vm.rightFirstTime.length - 1)+" more) ")]):_vm._e()]}}]),model:{value:(_vm.rightFirstTime),callback:function ($$v) {_vm.rightFirstTime=$$v},expression:"rightFirstTime"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"warning","disabled":!_vm.valid},on:{"click":_vm.onHold}},[_vm._v("On-Hold")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }