<template>
  <div class="on-upload">
    <v-dialog v-model="isOnUploadDialogVisible" persistent width="720px">
      <v-card>
        <v-card-title class="amber"
          ><span class="headline">Upload - {{ caseObject.UniqueIdentifier }}</span></v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col sm="4">
                  <v-text-field v-model="caseObject.UniqueIdentifier" label="Unique Identifier" readonly></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field v-model="caseObject.primaryExamId" label="Primary Exam Id" readonly></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field v-model="caseObject.secondaryExamId" label="Secondary Exam Id" readonly></v-text-field>
                </v-col>
                <v-container>
                  <v-row v-if="caseObject.caseType === 'TKA'">
                    <v-col sm="4">
                      <v-text-field
                        v-model="femoralImplantSize"
                        label="Femoral implant size"
                        required
                        :rules="femoralImplantSizeRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field
                        v-model="tibialImplantSize"
                        label="Tibial implant size"
                        required
                        :rules="tibialImplantSizeRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-select
                        :items="plannedGuidelinesItems"
                        v-model="plannedGuidelines"
                        :rules="plannedGuidelinesRules"
                        required
                        label="Planned w/ ______ guidelines"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col sm="4">
                      <v-text-field
                        v-model="cupSize"
                        label="Cup Size"
                        required
                        :rules="cupSizeRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field
                        v-model="headSize"
                        label="Head Size"
                        required
                        :rules="headSizeRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col sm="4">
                      <v-text-field
                        v-model="stemSize"
                        label="Stem Size"
                        required
                        :rules="stemSizeRules"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-col sm="12">
                  <v-textarea label="Upload Case Notes" v-model="uploadCaseNotes" rows="1" dense></v-textarea>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="poorScanReasons"
                    v-model="poorScanReason"
                    label="Select poor scan reason(s)"
                    multiple
                    dense
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ poorScanReason.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="rightFirstTimeCodes"
                    v-model="rightFirstTime"
                    label="Select right first time code(s)"
                    multiple
                    dense
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption" s>
                        (+{{ rightFirstTime.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col sm="12">
                  <v-checkbox
                    v-model="checkbox1"
                    :label="`Patient name: ${this.caseObject.patientName}`"
                    hide-details
                    dense
                  ></v-checkbox>
                  <v-checkbox v-model="checkbox2" :label="`Patient date of birth (DOB)`" hide-details></v-checkbox>
                  <v-checkbox
                    v-model="checkbox3"
                    :label="
                      `Planned operative side ${this.caseObject.operativeSide} matches MRN operative side ${this.caseObject.MRN}`
                    "
                    hide-details
                  ></v-checkbox>
                  <v-checkbox v-model="checkbox4" :label="`Attached to LifeImage/RepSuite`" hide-details></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel">Cancel</v-btn>
          <v-btn
            text
            color="warning"
            :disabled="!valid || isLoading || !checkbox1 || !checkbox2 || !checkbox3 || !checkbox4"
            @click="onUpload"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'on-upload',
  props: {
    showOnUploadDialog: {
      type: Boolean,
      default: false,
    },
    caseObject: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      uploadCaseNotes: '',
      poorScanReason: '',
      rightFirstTime: '',
      cupSize: '',
      cupSizeRules: [(v) => !!v || 'Cup size is required'],
      headSize: '',
      headSizeRules: [(v) => !!v || 'Head size is required'],
      stemSize: '',
      stemSizeRules: [(v) => !!v || 'Stem size is required'],
      femoralImplantSize: '',
      femoralImplantSizeRules: [(v) => !!v || 'Femoral implant size is required'],
      tibialImplantSize: '',
      tibialImplantSizeRules: [(v) => !!v || 'Tibial implant size is required'],
      plannedGuidelines: '',
      plannedGuidelinesItems: ['Varus', 'Valgus'],
      plannedGuidelinesRules: [(v) => !!v || 'Planned guidelines is required'],
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
    };
  },
  computed: {
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    isOnUploadDialogVisible: {
      get() {
        return this.showOnUploadDialog;
      },
      set(value) {
        this.$emit('on-upload-dialog-state-change', value);
      },
    },
    pScore() {
      if (this.caseObject.isUrgent) {
        return `0_${this.$moment(this.caseObject.needDate).format('YYYYMMDD')}_${this.$moment(
          this.caseObject.shareDate
        ).format('YYYYMMDD')}`;
      } else {
        return `1_${this.$moment(this.caseObject.needDate).format('YYYYMMDD')}_${this.$moment(
          this.caseObject.shareDate
        ).format('YYYYMMDD')}`;
      }
    },
    poorScanReasons() {
      if (this.caseObject.caseType === 'TKA') {
        return [
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Rotated ankle',
          'Side switch',
        ];
      } else {
        return [
          'Bent/rotated leg',
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Side switch',
        ];
      }
    },
    rightFirstTimeCodes() {
      if (this.caseObject.caseType === 'TKA' && this.caseObject.isTDA === false) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[PR] False acceptance/rejection',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Not submitted for high-risk evaluation',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else if (
        (this.caseObject.caseType === 'THA' || this.caseObject.caseType === 'Cadaver') &&
        this.caseObject.isTDA === false
      ) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[IS] False acceptance/rejection',
          '[IS] Model(s) labeled incorrectly',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[IS] Threshold not set to minimum',
          '[IS] Work not saved – no files (UTT)',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[FS] STLs labeled incorrectly',
          '[FS] Work not saved – no files (UTT)',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[IS] False acceptance/rejection',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut/Short models',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      }
    },
    checkbox() {
      if (this.checkbox1 && this.checkbox2 && this.checkbox3 && this.checkbox4) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions('cases', { updateCase: 'updateCase' }),
    onCancel() {
      // this.$refs.form.reset();
      this.isOnUploadDialogVisible = false;
    },
    async onUpload() {
      this.isLoading = true;
      this.caseObject['pScore'] = this.pScore;
      this.caseObject['uploadCaseNotes'] = this.uploadCaseNotes;
      this.caseObject['poorScanReason'] = this.poorScanReason;
      this.caseObject['rightFirstTime'] = this.rightFirstTime;
      this.caseObject['segRegion'] = this.currentAccount.region;
      this.caseObject['caseStatus'] = 'Uploaded';
      this.caseObject['currentlyAssignedRep'] = '';
      this.caseObject['planUpload'] = {
        repEmail: this.currentAccount.email,
        timeStamp: this.$moment().utc(),
      };
      this.caseObject['updatedBy'] = this.$store.state.accounts.account.username;
      this.caseObject['updatedDate'] = this.$moment().utc();

      if (this.caseObject.caseType === 'TKA') {
        this.caseObject['femoralImplantSize'] = this.femoralImplantSize;
        this.caseObject['tibialImplantSize'] = this.tibialImplantSize;
        this.caseObject['plannedGuidelines'] = this.plannedGuidelines;
      } else {
        this.caseObject['cupSize'] = this.cupSize;
        this.caseObject['headSize'] = this.headSize;
        this.caseObject['stemSize'] = this.stemSize;
      }

      await this.$http.post(
        'https://prod-50.eastus2.logic.azure.com:443/workflows/1ace7c6a530142108bd7e3c402bbcb80/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-9y-wotcNO-Lpwwwo6fT8E-7Jbwr0GA6--pAR0ka_es',
        this.caseObject
      );

      await this.updateCase(this.caseObject);
      this.isLoading = false;
      this.$router.push({ name: 'Tasks' });
      // this.$refs.form.reset();
      // this.isOnAcceptDialogVisible = false;
    },
  },
  watch: {},
};
</script>

<style></style>
