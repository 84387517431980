<template>
  <div class="on-hold">
    <v-dialog v-model="isOnHoldDialogVisible" persistent width="720px">
      <v-card>
        <v-card-title class="amber"
          ><span class="headline">On-Hold - {{ caseObject.UniqueIdentifier }}</span></v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col sm="6">
                  <v-select
                    :items="onHoldReasons"
                    v-model="onHoldReason"
                    :rules="onHoldReasonRules"
                    required
                    label="Select On-Hold reason(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"> (+{{ onHoldReason.length - 1 }} more) </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col sm="12">
                  <v-textarea label="On-Hold Case Notes" v-model="onHoldCaseNotes" rows="3"></v-textarea>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="poorScanReasons"
                    v-model="poorScanReason"
                    label="Select poor scan reason(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ poorScanReason.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="rightFirstTimeCodes"
                    v-model="rightFirstTime"
                    label="Select right first time code(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption" s>
                        (+{{ rightFirstTime.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel">Cancel</v-btn>
          <v-btn text color="warning" :disabled="!valid" @click="onHold">On-Hold</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'on-hold',
  props: {
    showOnHoldDialog: {
      type: Boolean,
      default: false,
    },
    caseObject: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      valid: false,
      onHoldReason: '',
      onHoldReasonRules: [(v) => !!v || 'On-Hold reason is required'],
      onHoldCaseNotes: '',
      poorScanReason: '',
      rightFirstTime: '',
    };
  },
  computed: {
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    isOnHoldDialogVisible: {
      get() {
        return this.showOnHoldDialog;
      },
      set(value) {
        this.$emit('on-hold-dialog-state-change', value);
      },
    },
    pScore() {
      if (this.caseObject.isUrgent) {
        return `0_${this.$moment(this.caseObject.needDate).format('YYYYMMDD')}_${this.$moment(
          this.caseObject.shareDate
        ).format('YYYYMMDD')}`;
      } else {
        return `1_${this.$moment(this.caseObject.needDate).format('YYYYMMDD')}_${this.$moment(
          this.caseObject.shareDate
        ).format('YYYYMMDD')}`;
      }
    },
    onHoldReasons() {
      if (this.caseObject.caseType === 'TKA') {
        return ['High-risk', 'Incomplete upload', 'Revision confirmation', 'Missing DOB'];
      } else {
        return ['High-risk', 'Incomplete upload', 'Revision confirmation', 'Missing DOB', 'Missing gender'];
      }
    },
    poorScanReasons() {
      if (this.caseObject.caseType === 'TKA') {
        return [
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Rotated ankle',
          'Side switch',
        ];
      } else {
        return [
          'Bent/rotated leg',
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Side switch',
        ];
      }
    },
    rightFirstTimeCodes() {
      if (this.caseObject.caseType === 'TKA' && this.caseObject.isTDA === false) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[PR] False acceptance/rejection',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Not submitted for high-risk evaluation',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else if (
        (this.caseObject.caseType === 'THA' || this.caseObject.caseType === 'Cadaver') &&
        this.caseObject.isTDA === false
      ) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[IS] False acceptance/rejection',
          '[IS] Model(s) labeled incorrectly',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[IS] Threshold not set to minimum',
          '[IS] Work not saved – no files (UTT)',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[FS] STLs labeled incorrectly',
          '[FS] Work not saved – no files (UTT)',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[IS] False acceptance/rejection',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut/Short models',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      }
    },
  },
  methods: {
    ...mapActions('cases', { updateCase: 'updateCase' }),
    onCancel() {
      this.$refs.form.reset();
      this.isOnHoldDialogVisible = false;
    },
    async onHold() {
      this.caseObject['pScore'] = this.pScore;
      this.caseObject['onHoldReason'] = this.onHoldReason;
      this.caseObject['onHoldCaseNotes'] = this.onHoldReason.concat(this.onHoldCaseNotes);
      this.caseObject['poorScanReason'] = this.poorScanReason;
      this.caseObject['rightFirstTime'] = this.rightFirstTime;
      this.caseObject['currentlyAssignedRep'] = '';
      this.caseObject['isOnHold'] = true;
      this.caseObject['updatedBy'] = this.$store.state.accounts.account.username;
      this.caseObject['updatedDate'] = this.$moment().utc();
      if (this.caseObject.caseStatus.includes('Data Review')) {
        this.caseObject['caseStatus'] = 'On Hold - Data Review';
      }
      if (this.caseObject.caseStatus.includes('Initial Segmentation')) {
        this.caseObject['caseStatus'] = 'On Hold - Initial Segmentation';
      }
      if (this.caseObject.caseStatus.includes('Segmentation Review')) {
        this.caseObject['caseStatus'] = 'On Hold - Segmentation Review';
      }
      if (this.caseObject.caseStatus.includes('Plan Review & Upload')) {
        this.caseObject['caseStatus'] = 'On Hold - Plan Review & Upload';
      }
      console.log(this.caseObject);
      await this.$http.post(
        'https://prod-38.eastus2.logic.azure.com:443/workflows/ab0f9d9206924a3fa27436594688c2c5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=34tEWbwl_Zk18cQ5ey7bxcm2qfO069FsPvILrhkOjmM',
        this.caseObject
      );
      await this.updateCase(this.caseObject);
      this.$router.push({ name: 'Tasks' });
      // this.$refs.form.reset();
      // this.isOnHoldDialogVisible = false;
    },
  },
  watch: {
    caseObject: {
      deep: true,
      handler() {
        console.log('case Object: ', this.caseObject);
      },
    },
    onHoldReason: function(newValue, oldValue) {
      console.log('On-hold reason changed: ', { newValue: newValue, oldValue: oldValue });
    },
  },
};
</script>

<style></style>
