<template>
	<div class="case" :key="caseKey">
		<v-row mb-10>
			<v-col sm="12">
				<v-alert v-if="isAutoCreatedCase && alertTypeACC === 'success'" :type="alertTypeACC" elevation="2">
					<span class="">
						This case was generated through the automated case creation service (ACC).
					</span>
				</v-alert>
				<v-alert v-if="isAutoCreatedCase && alertTypeACC === 'error'" :type="alertTypeACC" elevation="2">
					<span v-show="isPrimaryExamIdExist">Duplicate primary exam id. </span>
					<span v-show="isPatientNameExist">Duplicate patient name. </span>
					<span v-show="!isPatientNameValid">No valid patient name format. </span>
					<span v-show="isNeedDateWeekend && this.caseObject.caseType === 'TKA'"
						>The need by date falls on a weekend.
					</span>
					<span v-show="isNeedDateHoliday && this.caseObject.caseType === 'TKA'"
						>The need by date falls on a holiday.
					</span>
					<span v-show="!isNeedDateInTwoDays && this.caseObject.caseType === 'TKA'"
						>The need by date is less than two days away.
					</span>
					<span v-show="isRejectExempt && !isNeedDateInTwoDays && this.caseObject.caseType === 'TKA'"
						>MPS is auto reject exempt.</span
					>
					<span v-show="!isRejectExempt && !isNeedDateInTwoDays && this.caseObject.caseType === 'TKA'"
						>MPS is not auto reject exempt.
					</span>
				</v-alert>
				<v-alert v-if="isAutoCreatedCase && alertTypeACC === 'warning'" :type="alertTypeACC" elevation="2">
					<span v-show="isPrimaryExamIdExist">Duplicate primary exam id. </span>
					<span v-show="isPatientNameExist">Duplicate patient name. </span>
					<span v-show="!isPatientNameValid">No valid patient name format. </span>
					<span v-show="isNeedDateWeekend && this.caseObject.caseType === 'TKA'"
						>The need by date falls on a weekend.
					</span>
					<span v-show="isNeedDateHoliday && this.caseObject.caseType === 'TKA'"
						>The need by date falls on a holiday.
					</span>
					<span v-show="!isNeedDateInTwoDays && this.caseObject.caseType === 'TKA'"
						>The need by date is less than two days away.
					</span>
					<span v-show="isRejectExempt && this.caseObject.caseType === 'TKA'">MPS is auto reject exempt.</span>
					<span v-show="!isRejectExempt && this.caseObject.caseType === 'TKA'">MPS is not auto reject exempt. </span>
				</v-alert>
				<span class="headline">{{ caseObject.UniqueIdentifier }} - {{ caseObject.patientName }}</span>
			</v-col>
		</v-row>
		<v-form v-model="valid">
			<v-row dense>
				<v-col sm="2">
					<v-text-field v-model="caseObject.caseType" label="Case Type" readonly></v-text-field>
				</v-col>
				<v-col sm="2">
					<v-text-field v-model="caseObject.UniqueIdentifier" label="Unique Identifier" readonly></v-text-field>
				</v-col>
				<v-col sm="4">
					<v-text-field v-model="caseObject.caseStatus" label="Case Status" readonly></v-text-field>
				</v-col>
				<v-col sm="4">
					<v-text-field
						v-if="currentlyAssignedRep"
						v-model="caseObject.currentlyAssignedRep"
						label="Currently Assigned Rep"
						readonly
					></v-text-field>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col sm="4">
					<v-combobox
						v-model="coveringRep"
						:items="items"
						item-text="mail"
						item-value="mail"
						:search-input.sync="search"
						:rules="coveringRepRules"
						required
						clearable
						@click:clear="items = []"
						@change="checkEmail(coveringRep)"
						label="MPS E-mail"
						return-object
					>
						<template v-slot:item="{ item, on, attrs }">
							<v-list-item v-bind="attrs" v-on="on">
								<v-list-item-content>
									<v-list-item-title v-text="`${item.displayName}`"></v-list-item-title>
									<v-list-item-subtitle v-text="item.mail"></v-list-item-subtitle>
									<v-list-item-subtitle v-text="item.officeLocation"></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-combobox>
				</v-col>
				<v-col sm="2">
					<v-menu
						ref="shareDateMenu"
						v-model="shareDateMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="shareDateFormated"
								readonly
								:rules="shareDateRules"
								required
								label="Share Date"
								append-icon="mdi-calendar"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="caseObject.shareDate"
							:max="nowDate"
							no-title
							@input="shareDateMenu = false"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col sm="2">
					<v-menu
						ref="needDateMenu"
						v-model="needDateMenu"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="needDateFormated"
								readonly
								:rules="needDateRules"
								required
								label="Need by Date"
								append-icon="mdi-calendar"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="caseObject.needDate"
							:min="nowDate"
							no-title
							@input="needDateMenu = false"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col sm="2">
					<v-switch v-model="caseObject.isUrgent" label="Urgent" class="pl-10"></v-switch>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col sm="4">
					<v-text-field
						v-model="caseObject.patientName"
						:rules="patientNameRules"
						required
						label="Patient Name"
					></v-text-field>
				</v-col>
				<v-col sm="2">
					<v-text-field
						v-model="caseObject.primaryExamId"
						:rules="primaryExamIdRules"
						required
						label="Primary Exam Id"
					></v-text-field>
				</v-col>
				<v-col sm="2">
					<v-text-field v-model="caseObject.secondaryExamId" label="Secondary Exam Id"></v-text-field>
				</v-col>
				<v-col sm="2">
					<v-switch v-model="caseObject.isTDA" label="TDA" disabled class="pl-10"></v-switch>
				</v-col>
				<v-col sm="2">
					<v-select
						v-if="caseObject.isTDA"
						:items="segRegions"
						v-model="caseObject.segRegion"
						:rules="segRegionRules"
						disabled
						label="Seg Region"
					></v-select>
				</v-col>
			</v-row>
		</v-form>
		<v-form v-model="valid2">
			<v-row dense>
				<v-col sm="8">
					<v-text-field v-model="caseObject.MRN" :rules="MRNRules" required label="MRN"></v-text-field>
				</v-col>
				<!-- <v-col sm="2">
          <v-switch v-model="caseObject.isTHA4" :disabled="isTHA4" label="THA 4.0" class="pl-10"></v-switch>
        </v-col> -->
				<v-col sm="2">
					<v-select
						:items="segWorkflow"
						v-model="caseObject.segWorkflow"
						label="Seg Workflow"
						:disabled="!caseObject.isTDA || !caseObject.caseStatus.includes('Data Review')"
					></v-select>
				</v-col>
				<v-col sm="2" v-if="caseObject.caseType == 'THA' || caseObject.caseType == 'Cadaver'">
					<v-select
						:items="thaVersions"
						v-model="caseObject.thaVersion"
						:rules="thaVersionRules"
						required
						label="THA Version"
					></v-select>
				</v-col>
				<v-col sm="2" v-if="caseObject.caseType == 'TKA'">
					<v-select
						:items="tkaVersions"
						v-model="caseObject.tkaVersion"
						:rules="tkaVersionRules"
						required
						label="TKA Version"
					></v-select>
				</v-col>
			</v-row>

			<v-row dense>
				<v-col sm="4">
					<v-select
						:items="operativeSides"
						v-model="caseObject.operativeSide"
						:rules="operativeSideRules"
						required
						label="Operative side"
					></v-select>
				</v-col>
				<v-col sm="4">
					<v-select
						:items="genders"
						v-model="caseObject.gender"
						:rules="genderRules"
						required
						label="Gender"
					></v-select>
				</v-col>
				<v-col sm="4">
					<v-autocomplete
						v-model="caseObject.surgeon"
						:loading="loadingSurgeons"
						:items="surgeonProfiles"
						item-text="displayName"
						item-value="displayName"
						:search-input.sync="searchSurgeon"
						:rules="surgeonRules"
						@change="onSurgeonChange"
						required
						clearable
						:label="this.caseObject.surgeon ? this.caseObject.surgeon.displayName : 'Surgeon'"
						return-object
					>
						<template v-slot:item="{ item, on, attrs }">
							<v-list-item v-bind="attrs" v-on="on">
								<v-list-item-content>
									<v-list-item-title v-text="`${item.displayName}`"></v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-autocomplete>
				</v-col>
			</v-row>
			<v-row dense v-if="this.caseObject.caseType === 'TKA'">
				<v-col sm="4">
					<v-select
						:items="insertTypes"
						v-model="caseObject.insertType"
						:rules="insertTypeRules"
						required
						label="Insert Type"
					></v-select>
				</v-col>
				<v-col sm="4">
					<v-select
						:items="basePlateTypes"
						v-model="caseObject.basePlate"
						:rules="basePlateTypeRules"
						required
						label="Base Plate Type"
					></v-select>
				</v-col>
			</v-row>
			<v-row dense v-if="this.caseObject.caseType !== 'TKA'">
				<v-col sm="4">
					<v-select
						:items="approachTypes"
						v-model="caseObject.approach"
						:rules="approachRules"
						required
						label="Approach"
					></v-select>
				</v-col>
				<v-col sm="4">
					<v-select
						:items="acetabularComponentTypes"
						v-model="caseObject.acetabularComponentType"
						:rules="acetabularComponentTypeRules"
						required
						label="Acetabular Component"
					></v-select>
				</v-col>
				<v-col sm="4">
					<v-select
						:items="femoralComponentTypes"
						v-model="caseObject.femoralComponentType"
						:rules="femoralComponentTypeRules"
						required
						label="Femoral Component"
					></v-select>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col sm="12">
					<v-textarea label="Internal Case Notes" v-model="caseObject.internalCaseNotes" rows="2" dense></v-textarea>
				</v-col>
			</v-row>
		</v-form>
		<v-row dense>
			<v-btn :disabled="!valid" @click="onSave(caseObject)" color="primary">SAVE</v-btn>
			<v-btn @click="onCancel" color="default" class="ml-5">Cancel</v-btn>
			<v-spacer></v-spacer>
			<v-btn
				v-if="onCreateTPRDisabled"
				:disabled="!validTPR"
				@click="onCreateTPR(caseObject)"
				class="mr-10"
				color="purple"
				><span class="white--text">Create TPR</span></v-btn
			>
			<v-btn v-if="onAcceptDisabled" :disabled="!validCase" @click="onAccept" color="success">Accept</v-btn>
			<v-btn v-if="onExecuteDisabled" :disabled="!validCase" @click="onExecute" color="success">Execute</v-btn>
			<v-btn v-if="onUploadDisabled" :disabled="!validCase" @click="onUpload" color="success">Upload</v-btn>
			<v-btn v-if="onRejectDisabled" :disabled="!validCase" @click="onReject" color="error" class="ml-10">Reject</v-btn>
			<v-btn v-if="onHoldDisabled" :disabled="!valid" @click="onHoldCase" color="warning" class="ml-10">On Hold</v-btn>
		</v-row>

		<OnHold
			:caseObject="caseObject"
			:showOnHoldDialog="onHoldDialog"
			@on-hold-dialog-state-change="onHoldDialogStateChange"
			:key="onHoldKey"
		/>
		<OnAccept
			:caseObject="caseObject"
			:showOnAcceptDialog="onAcceptDialog"
			@on-accept-dialog-state-change="onAcceptDialogStateChange"
			:key="onAcceptKey"
		/>
		<OnExecute
			:caseObject="caseObject"
			:showOnExecuteDialog="onExecuteDialog"
			@on-execute-dialog-state-change="onExecuteDialogStateChange"
			:key="onExecuteKey"
		/>
		<OnUpload
			:caseObject="caseObject"
			:showOnUploadDialog="onUploadDialog"
			@on-upload-dialog-state-change="onUploadDialogStateChange"
			:key="onUploadKey"
		/>
		<OnReject
			:caseObject="caseObject"
			:showOnRejectDialog="onRejectDialog"
			@on-reject-dialog-state-change="onRejectDialogStateChange"
			:key="onRejectKey"
		/>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
	import OnHold from '../components/OnHold.vue';
	import OnAccept from '../components/OnAccept.vue';
	import OnExecute from '../components/OnExecute.vue';
	import OnUpload from '../components/OnUpload.vue';
	import OnReject from '../components/OnReject.vue';
	import { mapGetters, mapActions } from 'vuex';
	export default {
		components: { OnHold, OnAccept, OnReject, OnExecute, OnUpload },
		data: () => ({
			pageName: 'Case',
			caseKey: 0,
			onHoldKey: 100,
			onAcceptKey: 200,
			onExecuteKey: 300,
			onUploadKey: 400,
			onRejectKey: 500,
			onHoldDialog: false,
			onAcceptDialog: false,
			onExecuteDialog: false,
			onUploadDialog: false,
			onRejectDialog: false,
			valid: false,
			valid2: false,
			items: [],
			search: '',
			searchSurgeon: '',
			coveringRepRules: [(v) => !!v || 'E-mail is required'],
			surgeonRules: [(v) => !!v || 'Surgeon is required'],
			// nowDate: this.$moment()
			//   .subtract(4, 'hours')
			//   .toISOString()
			//   .slice(0, 10),
			nowDate: new Date().toISOString().slice(0, 10),
			shareDateMenu: false,
			// shareDate: this.$moment()
			//   .subtract(4, 'hours')
			//   .toISOString()
			//   .slice(0, 10),
			shareDate: new Date().toISOString().slice(0, 10),
			shareDateRules: [(v) => !!v || 'Share date is required'],
			needDateMenu: false,
			needDate: '',
			needDateRules: [(v) => !!v || 'Need by date is required'],
			primaryExamIdRules: [(v) => !!v || 'Exam Id is required'],
			patientNameRules: [(v) => !!v || 'Patient name is required'],
			MRNRules: [(v) => !!v || 'MRN is required'],
			segRegionRules: [(v) => !!v || 'Region is required'],
			segRegions: ['US - Fort Lauderdale, FL', 'US - Kalamazoo, MI', 'APAC', 'SSP'],
			segWorkflow: ['Full Seg', 'Single Seg'],
			thaVersions: ['3.1', '4.0', '4.1', 'N/A'],
			tkaVersions: ['1.0', '2.0', 'N/A'],
			thaVersionRules: [(v) => !!v || 'THA version is required'],
			tkaVersionRules: [(v) => !!v || 'TKA version is required'],
			operativeSides: ['Left', 'Right', 'N/A'],
			operativeSideRules: [(v) => !!v || 'Operative side is required'],
			genderRules: [(v) => !!v || 'Gender is required'],
			insertTypes: ['CR', 'CS', 'PS', 'TS'],
			insertTypeRules: [(v) => !!v || 'Insert type is required'],
			basePlateTypes: ['All Poly', 'Cruciform', 'Tritanium', 'Universal'],
			basePlateTypeRules: [(v) => !!v || 'Base plate type is required'],
			approachRules: [(v) => !!v || 'Approach is required'],
			approachTypes: ['Postero-Lateral', 'Antero-Lateral', 'Direct Anterior', 'Direct Superior (THA 4.0 only)'],
			acetabularComponentTypeRules: [(v) => !!v || 'Acetabular component is required'],
			acetabularComponentTypes: [
				'Trident Hemispherical',
				'Trident PSL',
				'Trident Tritanium',
				'Trident II Hemispherical (THA 4.0 only)',
				'Trident II PSL (THA 4.0 only)',
				'Trident II Tritanium',
			],
			femoralComponentTypeRules: [(v) => !!v || 'Femoral component is required'],
			femoralComponentTypes: ['Accolade II', 'Secur-Fit Advanced', 'Anato', 'Exeter', 'Exeter US', 'Insignia'],
		}),
		computed: {
			...mapGetters('cases', { caseObject: 'getCase', cases: 'getCases', loading: 'getLoading' }),
			...mapGetters('users', { users: 'getUsers' }),
			...mapGetters('surgeons', { surgeons: 'getSurgeons', loadingSurgeons: 'getLoading' }),
			...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
			...mapGetters('holidays', { holidays: 'getHolidays' }),
			currentlyAssignedRep() {
				return this.caseObject.currentlyAssignedRep !== '';
			},
			coveringRep: {
				get() {
					return { mail: this.caseObject.coveringRepEmail };
				},
				set(value) {
					this.caseObject.coveringRepEmail = value.mail;
					this.caseObject.coveringRepGivenName = value.givenName;
					this.caseObject.coveringRepSurname = value.surname;
				},
			},
			pScore() {
				if (this.caseObject.isUrgent) {
					return `0_${this.$moment(this.caseObject.needDate).format('YYYYMMDD')}_${this.$moment(
						this.caseObject.shareDate
					).format('YYYYMMDD')}`;
				} else {
					return `1_${this.$moment(this.caseObject.needDate).format('YYYYMMDD')}_${this.$moment(
						this.caseObject.shareDate
					).format('YYYYMMDD')}`;
				}
			},
			surgeonProfiles() {
				const values = Object.values(this.surgeons);
				if (this.caseObject.caseType === 'TKA') {
					return values.filter((surgeon) => surgeon.surgeonType === 'TKA');
				} else {
					return values.filter((surgeon) => surgeon.surgeonType === 'THA');
				}
			},
			genders() {
				if (this.caseObject.caseType !== 'Cadaver') {
					return ['Male', 'Female', 'N/A'];
				} else {
					return ['Cadaver'];
				}
			},
			isTHA4() {
				if (this.caseObject.caseType === 'TKA') {
					return true;
				} else {
					return false;
				}
			},
			shareDateFormated: {
				get() {
					return this.caseObject.shareDate ? this.$moment(this.caseObject.shareDate).format('L') : null;
				},
				set() {},
			},
			needDateFormated: {
				get() {
					return this.caseObject.needDate ? this.$moment(this.caseObject.needDate).format('L') : '';
				},
				set() {},
			},
			validCase() {
				if (this.valid && this.valid2) {
					return true;
				} else {
					return false;
				}
			},
			validTPR() {
				if (this.caseObject.secondaryExamId.includes('R-') && this.caseObject.surgeon) {
					return true;
				} else {
					return false;
				}
			},
			onHoldDisabled() {
				if (this.caseObject.caseStatus === 'Uploaded' || this.caseObject.caseStatus.includes('On Hold')) {
					return false;
				} else {
					return true;
				}
			},
			onAcceptDisabled() {
				if (this.caseObject.caseStatus !== 'Assigned - Data Review') {
					return false;
				} else {
					return true;
				}
			},
			onCreateTPRDisabled() {
				if (
					this.caseObject.caseStatus === 'Assigned - Data Review' &&
					!this.isPrimaryExamIdValid &&
					!this.caseObject.isTPRCreated
				) {
					return true;
				} else {
					return false;
				}
			},
			isPrimaryExamIdValid() {
				if (this.caseObject.primaryExamId.includes('C-') || this.caseObject.primaryExamId.includes('R-')) {
					return true;
				} else {
					return false;
				}
			},
			onExecuteDisabled() {
				if (
					this.caseObject.caseStatus === 'Assigned - Initial Segmentation' ||
					this.caseObject.caseStatus === 'Assigned - Segmentation Review'
				) {
					return true;
				} else {
					return false;
				}
			},
			onUploadDisabled() {
				if (this.caseObject.caseStatus === 'Assigned - Plan Review & Upload') {
					return true;
				} else {
					return false;
				}
			},
			onRejectDisabled() {
				if (this.caseObject.caseStatus === 'Uploaded' || this.caseObject.caseStatus.includes('Rejected')) {
					return false;
				} else {
					return true;
				}
			},
			isAutoCreatedCase() {
				if (
					this.caseObject.createdBy === 'GCAS-Admin@stryker.com' &&
					this.caseObject.caseStatus.includes('Data Review')
				) {
					return true;
				} else {
					return false;
				}
			},
			isPrimaryExamIdExist() {
				let primaryExamId = this.cases.filter(
					(caseObject) => caseObject.primaryExamId === this.caseObject.primaryExamId
				);
				if (primaryExamId.length > 1) {
					return true;
				} else {
					return false;
				}
			},
			isPatientNameExist() {
				let patientName = this.cases.filter((caseObject) => caseObject.patientName === this.caseObject.patientName);
				if (patientName.length > 1) {
					return true;
				} else {
					return false;
				}
			},
			isPatientNameValid() {
				let nameReg = /^[a-zA-Z0-9^]+,\s[a-zA-Z0-9^]+$/i;
				return nameReg.test(this.caseObject.patientName);
			},
			isRejectExempt() {
				let user = this.checkEmail(this.coveringRep);
				if (!user) {
					return;
				} else {
					return user.autoRejectExempt;
				}
			},
			isNeedDateInTwoDays() {
				let days = [];
				let weekends = [];
				let holidays = [];
				let addDay = 0;

				let shareDate = this.$moment(this.caseObject.shareDate);
				let needDate = this.$moment(this.caseObject.needDate);
				if (!shareDate && !needDate) {
					return;
				} else {
					while (needDate.diff(shareDate, 'days') >= 0) {
						days.push(shareDate.format('YYYY-MM-DD'));
						shareDate.add(1, 'days');
					}
					holidays = this.holidays.filter((holiday) => days.includes(holiday.date));
					days.forEach((date) => {
						if (this.$moment(date).isoWeekday() == 6 || this.$moment(date).isoWeekday() == 7) {
							weekends.push(date);
						}
					});
					if (
						this.$moment(this.caseObject.shareDate).isoWeekday() == 6 ||
						this.$moment(this.caseObject.shareDate).isoWeekday() == 7
					) {
						addDay = 1;
					}
					if (
						this.holidays.some(
							(holiday) => this.$moment(this.caseObject.shareDate).format('YYYY-MM-DD') === holiday.date
						)
					) {
						addDay = 1;
					}

					let totalDays = days.length - (weekends.length + holidays.length) + addDay;
					console.log({
						days: days,
						addDay: addDay,
						weekends: weekends,
						holidays: holidays,
						totalDays: totalDays,
					});
					return totalDays >= 3;
				}
			},
			isNeedDateWeekend() {
				let date = this.$moment(this.caseObject.needDate);
				if (!date) {
					return;
				} else {
					let isWeekend = date.weekday() === 0 || date.weekday() === 6;
					return isWeekend;
				}
			},
			isNeedDateHoliday() {
				let date = this.$moment(this.caseObject.needDate);
				if (!date) {
					return;
				} else {
					let isHoliday = this.holidays.some(
						(holiday) => this.$moment(this.caseObject.needDate).format('YYYY-MM-DD') === holiday.date
					);
					return isHoliday;
				}
			},
			alertTypeACC() {
				if (
					this.isAutoCreatedCase &&
					!this.isPrimaryExamIdExist &&
					!this.isPatientNameExist &&
					this.isPatientNameValid &&
					(this.isNeedDateWeekend || this.isNeedDateHoliday || !this.isNeedDateInTwoDays) &&
					this.caseObject.caseType === 'TKA'
				) {
					return 'warning';
				} else if (
					this.isAutoCreatedCase &&
					!this.isPrimaryExamIdExist &&
					!this.isPatientNameExist &&
					this.isPatientNameValid
				) {
					return 'success';
				} else {
					return 'error';
				}
			},
		},
		methods: {
			...mapActions('surgeons', { setSurgeons: 'setSurgeons' }),
			...mapActions('surgeons', { querySurgeons: 'querySurgeons' }),
			...mapActions('cases', { updateCase: 'updateCase' }),
			onHoldDialogStateChange(state) {
				this.onHoldDialog = state;
			},
			onAcceptDialogStateChange(state) {
				this.onAcceptDialog = state;
			},
			onExecuteDialogStateChange(state) {
				this.onExecuteDialog = state;
			},
			onUploadDialogStateChange(state) {
				this.onUploadDialog = state;
			},
			onRejectDialogStateChange(state) {
				this.onRejectDialog = state;
			},
			onHoldCase() {
				this.onHoldDialog = true;
			},
			onAccept() {
				this.onAcceptDialog = true;
			},
			onExecute() {
				this.onExecuteDialog = true;
			},
			onUpload() {
				this.onUploadDialog = true;
			},
			onReject() {
				this.onRejectDialog = true;
			},
			async onSave(caseObject) {
				this.caseObject['pScore'] = this.pScore;
				this.caseObject['updatedBy'] = this.$store.state.accounts.account.username;
				this.caseObject['updatedDate'] = this.$moment().utc();
				this.caseKey += 1;
				await this.updateCase(caseObject);
				this.$router.push({ name: 'Tasks' });
			},
			async onCreateTPR(caseObject) {
				this.caseObject['caseStatus'] = 'Awaiting Data Review';
				this.caseObject['currentlyAssignedRep'] = '';
				this.caseObject['isTPRCreated'] = true;
				this.caseObject['updatedBy'] = this.$store.state.accounts.account.username;
				this.caseObject['updatedDate'] = this.$moment().utc();
				this.caseKey += 1;
				await this.updateCase(caseObject);
				this.$router.push({ name: 'Tasks' });
			},
			onCancel() {
				this.caseKey += 1;
				this.$router.push({ name: 'Tasks' });
			},
			onSurgeonChange() {
				if (this.caseObject.surgeon && this.caseObject.surgeon.surgeonType === 'TKA') {
					this.caseObject.insertType = this.caseObject.surgeon.insertType;
					this.caseObject.basePlate = this.caseObject.surgeon.basePlate;
				}
				if (this.caseObject.surgeon && this.caseObject.surgeon.surgeonType === 'THA') {
					this.caseObject.approach = this.caseObject.surgeon.approach;
					this.caseObject.acetabularComponentType = this.caseObject.surgeon.acetabularComponentType;
					this.caseObject.femoralComponentType = this.caseObject.surgeon.femoralComponentType;
				}
			},
			checkEmail(value) {
				if (!value) {
					return;
				} else {
					return this.users.find((user) => user.email === value.mail && user.userType === 'MPS');
				}
			},
			async getUsers(newValue) {
				await this.$store.dispatch('accounts/getToken');
				setTimeout(() => {
					this.$http
						.get(`https://graph.microsoft.com/v1.0/users?$search="mail:${newValue}"&$orderby=displayName&$count=true`, {
							headers: {
								authorization: `bearer ${this.$store.state.accounts.accessToken}`,
								ConsistencyLevel: 'eventual',
							},
						})
						.then((res) => {
							this.items = res.data.value;
						})
						.catch((err) => {
							console.log(err);
						});
				}, 1000);
			},
			getSurgeonDisplayName() {
				return this.caseObject.surgeon ? this.caseObject.surgeon.displayName : 'displayName';
			},
		},
		watch: {
			onHoldDialog: function(newValue) {
				if (!newValue) {
					console.log('onHoldDialog Rerender: ', { newValue: newValue });
					this.onHoldKey += 1;
				}
			},
			onAcceptDialog: function(newValue) {
				if (!newValue) {
					console.log('onAcceptDialog Rerender: ', { newValue: newValue });
					this.onAcceptKey += 1;
				}
			},
			onExecuteDialog: function(newValue) {
				if (!newValue) {
					console.log('onExecuteDialog Rerender: ', { newValue: newValue });
					this.onAcceptKey += 1;
				}
			},
			onUploadDialog: function(newValue) {
				if (!newValue) {
					console.log('onUploadDialog Rerender: ', { newValue: newValue });
					this.onUploadKey += 1;
				}
			},
			onRejectDialog: function(newValue) {
				if (!newValue) {
					console.log('onRejectDialog Rerender: ', { newValue: newValue });
					this.onRejectKey += 1;
				}
			},
			search: function(newValue) {
				if (!newValue) {
					return;
				}
				if (newValue && newValue !== this.coveringRep && newValue.length >= 3) {
					this.getUsers(newValue);
				}
			},
			searchSurgeon: function(newValue) {
				if (!newValue) {
					return;
				}
				if (!newValue) {
					return;
				}
				if (newValue && newValue.length >= 3) {
					// this.setSurgeons();
					let payload = {
						surgeonType: this.caseObject.caseType,
						displayName: newValue,
					};
					this.querySurgeons(payload);
				}
			},
			coveringRep: function(newValue) {
				if (newValue) {
					let user = this.checkEmail(newValue);
					if (!user) {
						this.coveringRepRegion = 'US';
					} else {
						this.caseObject.coveringRepRegion = user.region;
					}
				}
				console.log(newValue);
			},
			caseObject: {
				deep: true,
				handler() {
					console.log('case Object: ', this.caseObject);
					if (this.caseObject.secondaryExamId.includes('R-')) {
						this.caseObject.isTDA = true;
						this.caseObject.segRegion = this.$store.state.accounts.currentAccount.region;
					}
				},
			},
			'$route.params.id': {
				deep: true,
				handler() {
					this.caseKey += 1;
					this.$store.dispatch('cases/getCase', this.$route.params.id);
				},
			},
		},
		async created() {
			this.$store.dispatch('cases/getCase', this.$route.params.id);
		},
	};
</script>

<style></style>
