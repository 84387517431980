<template>
  <div class="on-accept">
    <v-dialog v-model="isOnAcceptDialogVisible" persistent width="720px">
      <v-card>
        <v-card-title class="amber"
          ><span class="headline">Accept - {{ caseObject.UniqueIdentifier }}</span></v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col sm="12">
                  <v-textarea label="Openning Case Notes" v-model="onAcceptCaseNotes" rows="3"></v-textarea>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="poorScanReasons"
                    v-model="poorScanReason"
                    label="Select poor scan reason(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ poorScanReason.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :items="rightFirstTimeCodes"
                    v-model="rightFirstTime"
                    label="Select right first time code(s)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip x-small v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption" s>
                        (+{{ rightFirstTime.length - 1 }} more)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel">Cancel</v-btn>
          <v-btn text color="warning" :disabled="!valid || isLoading" @click="onAccept">Accept</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'on-accept',
  props: {
    showOnAcceptDialog: {
      type: Boolean,
      default: false,
    },
    caseObject: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      onAcceptCaseNotes: '',
      poorScanReason: '',
      rightFirstTime: '',
    };
  },
  computed: {
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    isOnAcceptDialogVisible: {
      get() {
        return this.showOnAcceptDialog;
      },
      set(value) {
        this.$emit('on-accept-dialog-state-change', value);
      },
    },
    pScore() {
      if (this.caseObject.isUrgent) {
        return `0_${this.$moment(this.caseObject.needDate).format('YYYYMMDD')}_${this.$moment(
          this.caseObject.shareDate
        ).format('YYYYMMDD')}`;
      } else {
        return `1_${this.$moment(this.caseObject.needDate).format('YYYYMMDD')}_${this.$moment(
          this.caseObject.shareDate
        ).format('YYYYMMDD')}`;
      }
    },
    poorScanReasons() {
      if (this.caseObject.caseType === 'TKA') {
        return [
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Rotated ankle',
          'Side switch',
        ];
      } else {
        return [
          'Bent/rotated leg',
          'Incorrect number of files',
          'Need by date change',
          'Noise/Scatter',
          'Resolution matrix greater than 512x512',
          'Reverted to legacy -case not in TDA',
          'Reverted to legacy -case rejected in TDA',
          'Side switch',
        ];
      }
    },
    rightFirstTimeCodes() {
      if (this.caseObject.caseType === 'TKA' && this.caseObject.isTDA === false) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[PR] False acceptance/rejection',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Not submitted for high-risk evaluation',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else if (
        (this.caseObject.caseType === 'THA' || this.caseObject.caseType === 'Cadaver') &&
        this.caseObject.isTDA === false
      ) {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect patient downloaded',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[DR] Work not saved – no files (UTT)',
          '[IS] False acceptance/rejection',
          '[IS] Model(s) labeled incorrectly',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[IS] Threshold not set to minimum',
          '[IS] Work not saved – no files (UTT)',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[FS] STLs labeled incorrectly',
          '[FS] Work not saved – no files (UTT)',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      } else {
        return [
          '[DR] False acceptance/rejection',
          '[DR] Incorrect information on router/TPR',
          '[DR] Incorrect series selected',
          '[DR] Not submitted for high-risk evaluation',
          '[IS] False acceptance/rejection',
          '[IS] Not submitted for high-risk evaluation',
          '[IS] Over/under segmentation',
          '[FS] False acceptance/rejection',
          '[FS] Improper hemi-cut/Short models',
          '[FS] Not submitted for high-risk evaluation',
          '[FS] Over/under segmentation',
          '[PR] Incomplete session file exported',
          '[PR] Incorrect patient attachment',
          '[PR] Plan not attached',
          '[PR] Poor quality',
          '[PR] Work not saved – no files (UTT)',
        ];
      }
    },
  },
  methods: {
    ...mapActions('cases', { updateCase: 'updateCase' }),
    onCancel() {
      this.$refs.form.reset();
      this.isOnAcceptDialogVisible = false;
    },
    async onAccept() {
      this.isLoading = true;
      this.caseObject['pScore'] = this.pScore;
      this.caseObject['onAcceptCaseNotes'] = this.onAcceptCaseNotes;
      this.caseObject['poorScanReason'] = this.poorScanReason;
      this.caseObject['rightFirstTime'] = this.rightFirstTime;
      this.caseObject['isReject'] = false;
      this.caseObject['isOnHold'] = false;
      this.caseObject['segRegion'] = this.currentAccount.region;
      this.caseObject['dataReview'] = { repEmail: this.currentAccount.email, timeStamp: this.$moment().utc() };
      this.caseObject['updatedBy'] = this.$store.state.accounts.account.username;
      this.caseObject['updatedDate'] = this.$moment().utc();

      if (this.caseObject.isTDA && this.caseObject.segWorkflow === 'Full Seg') {
        this.caseObject['caseStatus'] = 'Assigned - Initial Segmentation';
      }
      if (!this.caseObject.isTDA && this.caseObject.caseType === 'TKA') {
        this.caseObject['caseStatus'] = 'Awaiting Plan Review & Upload';
        this.caseObject['currentlyAssignedRep'] = '';
      }
      if (!this.caseObject.isTDA && this.caseObject.caseType !== 'TKA') {
        this.caseObject['caseStatus'] = 'Awaiting Initial Segmentation';
        this.caseObject['currentlyAssignedRep'] = '';
      }
      if (this.caseObject.isTDA && this.caseObject.segWorkflow === 'Single Seg') {
        this.caseObject['caseStatus'] = 'Awaiting Initial Segmentation';
        this.caseObject['currentlyAssignedRep'] = '';
      }

      console.log(this.caseObject);
      await this.$http.post(
        'https://prod-54.eastus2.logic.azure.com:443/workflows/c1115934c57e41569f3c64f2ee7f2ff0/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=sLAWD0ehC-s9hvGyyJYcT2arahgiklU6Gr7QOgnuIRg',
        this.caseObject
      );

      await this.updateCase(this.caseObject);
      this.isLoading = false;
      this.$router.push({ name: 'Tasks' });
      // this.$refs.form.reset();
      // this.isOnAcceptDialogVisible = false;
    },
  },
  watch: {},
};
</script>

<style></style>
